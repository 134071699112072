<template>
  <div>
    <div
      class="intro-container row-24 m-0 justify-center items-center"
    >
      <div
        ref="videoContainer"
        class="video-container col-24 m-0 p-0"
        @mousemove="getMousePosition"
        @click="playVideo"
      >
        <button
          ref="playBtn"
          class="playBtn"
          :class="{ fadeOut: playBtnFadeout}"
          :style="{top: mouseY + 'px', left: mouseX + 'px'}"
        >
          {{ playBtnText }}
        </button>
        <video
          ref="video"
          class="fullsize-video"
          :src="movie.moviefull.length ? movie.moviefull[0].link : null"
          :poster="movie.vorschaubild.large ? movie.vorschaubild.large : null"
          preload="auto"
          playsinline
          disablePictureInPicture
          controlsList="nodownload noremoteplayback"
        />
      </div>
      <ScrollIndicator />
      <BackButton :link="movie.link" :slug="movie.slug" />
    </div>

    <div class="movie-content bg-white row-24 m-0">
      <div class="movie-title col-22 offset-1 md:col-4 md:offset-1">
        <h2 v-html="movie.filmtitel" />
        <h3>{{ movie.regie }}</h3>
      </div>

      <div class="movie-produktion col-22 offset-1 md:col-6 md:offset-1 lg:col-6 xl:col-5">
        <p v-html="movie.produktion" />
      </div>

      <div class="movie-crew col-22 offset-1 md:col-6 md:offset-0 lg:col-6 ">
        <p v-html="movie.crew" />
      </div>

      <div class="movie-screenshots-container col-22 offset-1 md:col-14 md:offset-6 lg:offset-5">
        <div
          v-for="screenshot in movie.screenshots"
          :key="screenshot.id"
          class="movie-screenshot"
        >
          <img :src="screenshot.large" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import BackButton from '../parts/BackButton.vue';
import ScrollIndicator from '../parts/ScrollIndicator.vue';

export default {
  components: {
    ScrollIndicator,
    BackButton,
  },
  props: {
    payload: Object,
  },
  data() {
    return {
      movie: this.payload,
      isVideoPlaying: false,
      timeOut: null,
      mouseX: '50%',
      mouseY: '50%',
      playBtnFadeout: false,
    };
  },
  computed: {
    playBtnText() {
      return this.isVideoPlaying ? 'Pause' : 'Play';
    },
  },
  mounted() {
    this.$refs.videoContainer.addEventListener('mousemove', this.hideControls);
  },
  unmounted() {
    // not necessary ?
    // this.$refs.videoContainer.removeEventListener('mousemove', this.hideControls);
    clearTimeout(this.timeOut);
  },
  methods: {
    getMousePosition(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.mouseX = x;
      this.mouseY = y;
    },
    playVideo() {
      if (!this.isVideoPlaying) {
        this.$refs.video.play();
        this.isVideoPlaying = !this.isVideoPlaying;
      } else if (this.isVideoPlaying) {
        this.$refs.video.pause();
        this.isVideoPlaying = !this.isVideoPlaying;
      }
    },
    hideControls() {
      this.playBtnFadeout = false;
      if (this.$refs.video.ended) {
        this.isVideoPlaying = false;
      }
      clearTimeout(this.timeOut);
      this.timeOut = setTimeout(() => {
        this.playBtnFadeout = true;
      }, 750);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";

.intro-container {
  overflow-x: visible;
}

.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: visible;
  cursor: none;
  .playBtn {
    pointer-events: none;
    position: absolute;
    left: 50%;
    top: 50%;
    will-change: top, left;
    transform: translate(-50%, -50%);
    color: var(--color-yellow);
    font-family: var(--font-primary);
    font-size: 2.5rem;
    z-index: 5;
    opacity: 1;
    width: 150px;
    transition: opacity .5s ease-out;
    &.fadeOut {
      opacity: 0;
    }
}
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.movie {
  &-content {
    padding: 4rem 0;
    position: relative;
    :deep(.movie-title) {
      position: sticky;
      top: 4rem;
      @media (max-width: theme("screens.md")) {
          position: static;
        }
      h2, p {
        text-transform: uppercase;
        margin: 0;
        font-size: 1.75rem;
        @media (max-width: theme("screens.md")) {
          line-height: 1.2;
        }
      }
      h3 {
        font-size: px(20);
        @media (max-width: theme("screens.md")) {
          font-size: px(18);
          margin: .5rem 0;
        }
      }
    }
  }
  &-produktion, &-crew {
    :deep(li) {
      font-size: 1rem;
    }
  }
  &-screenshot {
    margin-bottom: 1rem;
    background-position: center;
    background-size: cover;
    img {
      width: 100%;
      max-height: 800px;
      height: auto;
      object-fit: contain;
      object-position: center;
      @media (max-width: theme("screens.sm")) {
        max-height: 400px;
        }
    }
  }
}
</style>
